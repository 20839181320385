import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';
import config from '../../../config/website';
import theme from '../../../config/theme';
import './Navigation.scss';

const Wrapper = styled.header`
  align-items: center;
  display: flex;
  padding: 1rem 0 3rem 0;
  a {
    color: ${props => props.theme.colors.body_color};
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: ${props => props.theme.brand.primary};
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 1rem 0 3rem 0;
    flex-wrap: wrap;
  }
`;

const active = css`
  text-decoration: underline;
`;

const Nav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 0 ${props => props.theme.spacer.horizontal};
  a:not(:first-child) {
    margin-left: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    order: 2;
  }
`;

const Name = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  a {
    font-size: 23px;
    font-family: ${`${config.headerFontFamily}, sans-serif`};
    &:hover,
    &:focus {
      color: ${props => props.theme.colors.body_color};
      text-decoration: none;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    order: 1;
    flex: 1 0 100%;
    margin-bottom: 0.75rem;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 0 ${props => props.theme.spacer.horizontal};
  a {
    font-size: 1.25rem;
    line-height: 20px;
  }
  a:not(:first-child) {
    margin-left: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    order: 3;
  }
`;

const Navigation = ({ white }) => (
  <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
    <Link activeClassName="active" className={`${white && `white`} navbar-brand`} to="/">
      Aiden Kwok
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <p>Menu</p>
    </button>

    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav navbar-right">
        <li className="nav-item">
          <Link
            className={`${white && `white`} nav-link top-left`}
            to="/work"
            activeClassName="active"
          >
            Work
          </Link>
        </li>
        <div className="bottom">
          <li className='nav-item'>
            <Link
              className={`${white && `white`} nav-link`}
              to="/about"
              activeClassName="active"
            >
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`${white && `white`} nav-link`}
              to="/contact"
              activeClassName="active"
            >
              Contact
            </Link>
          </li>
        </div>
      </ul>
    </div>
  </nav>
);

Navigation.defaultProps = {
  white: false,
};

Navigation.propTypes = {
  white: PropTypes.bool,
};

export default Navigation;
