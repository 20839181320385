import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { SEO, Navigation, Footer } from 'components';
import theme from '../../config/theme';
import '../fonts.scss';

const Layout = ({ children, nav }) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <SEO />
      {nav && <Navigation />}
      {children}
    </React.Fragment>
  </ThemeProvider>
);

export default Layout;

Layout.defaultProps = {
  nav: true,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  nav: PropTypes.bool,
};
