import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import styled, { css } from 'react-emotion';
import { Link } from 'gatsby';
import {Navigation, SEO} from '.';
import PropTypes from 'prop-types';
import config from '../../config/website';
import { FaArrowLeft } from "react-icons/fa";

const Backbutton = styled.button`
  color: grey;
  padding-top: 1em;
  background-color: transparent;
  border: none;
  &:hover {
    opacity: 0.5;
  }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #011627;
  color: white;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  p {
    margin: 0.5em 1em 0;
  }
`;

const Button = styled(Link)`
  text-align: center;
  color: transparent;
  border: 2px solid white;
  background-color: transparent;
  padding: 0.5em 0.75em;
  transition: ease-in-out 0.2s;
  @media (max-width: 992px) {
    color: white;
  }
  &:hover {
    transition: ease-in-out 0.2s;
    background-color: #00F0B5;
    color: white;
    text-decoration: none;
  }
`;

export default class Choice extends PureComponent {
  render() {
    return (
      <Container>
        <SEO />
        <Helmet title={this.props.title === '' ? config.siteTitle : `${this.props.title} | ${config.siteTitle}`} />
        <Navigation white />
        <Wrapper>
          {this.props.children}
          <ButtonContainer>
            <Button
              to={this.props.firstTo}
            >
              {this.props.first}
            </Button>

            <p>or</p>

            <Button
              to={this.props.secondTo}
            >
              {this.props.second}
            </Button>
          </ButtonContainer>
          {this.props.back && (
          
            <Backbutton onClick={() => window.history.back()}> <FaArrowLeft /> back</Backbutton>
          )}
        </Wrapper>

      </Container>
    )
  }
}

Choice.defaultProps = {
  title: '',
  back: false,
};

Choice.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  title: PropTypes.string,
  first: PropTypes.string.isRequired,
  firstTo: PropTypes.string.isRequired,
  second: PropTypes.string.isRequired,
  secondTo: PropTypes.string.isRequired,
  back: PropTypes.bool,
};
