import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'react-emotion';
import Img from 'gatsby-image';
import { FaArrowRight } from "react-icons/fa";

const TextBlock = styled.div`
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const LinkContainer = styled(Link)`
  background-color: #011627;
  border: 7px solid #00F0B5;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.2s;
  &:hover {
    opacity: 0.5;
    transition: ease-in-out 0.2s;
    text-decoration: none !important;
    color: white;
  }

  h4 {
    margin: 0 2em;
    text-align: center;
  }
`;

const Container = styled.div`
  max-width: 992px;
  margin: 80px auto;
  display: flex;
  flex-direction: column;
  color: #011627;
  @media (max-width: 1030px) {
    padding: 1em;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); 
  row-gap: 20px;
  column-gap: 20px;
`;

const Item = styled.div`
  height: 200px;
  position: relative;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    position: absolute;
    background-color: #011627;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: ease-in-out 0.2s;
    text-decoration: none;
    h4 {
      text-align: center;
      margin: 0 2em;
    }
    &:hover {
      opacity: 1;
      transition: ease-in-out 0.2s;
    }
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  > div {
    height: 100%;
  }
`;

const ProjectListing = ({ projectEdges, title}) => {
  return (
    <Container>
      {title ? (
        <TextBlock>
          <h1>
            {title}
          </h1>
        </TextBlock>
      ): (
        <TextBlock>
            <h1>All Projects</h1>
        </TextBlock>
      )}
      <Wrapper>
        {projectEdges.map(project => {
          return (
            <Item key={project.node.fields.slug}>
              <Content>
                <ImageWrapper>
                  <Img fluid={project.node.frontmatter.cover.childImageSharp.fluid} />
                </ImageWrapper>
                <Link to={project.node.fields.slug}>
                  <h4 style={{color: 'white'}}>{project.node.frontmatter.title}</h4>
                </Link>
              </Content>
            </Item>
          );
        })}
        {title && (
          <Item>
            <LinkContainer to="/work">
              <h4> See more of my work here <FaArrowRight /></h4>
            </LinkContainer>
          </Item>
        )}
      </Wrapper>
    </Container>
  );
};

export default ProjectListing;


ProjectListing.propTypes = {
  projectEdges: PropTypes.array.isRequired,
};
